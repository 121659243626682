<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card pa-3">
        <h3>Request Refund</h3>
        <v-card class="pa-2 rounded-lg mt-3" outlined>
          <div class="d-flex align-center mb-1">
            <div class="unsend"></div>
            <p class="small_txt color_txt mb-0 ml-2">Pending</p>
          </div>
          <div class="d-flex align-center mb-1">
            <div class="sent"></div>
            <p class="small_txt color_txt mb-0 ml-2">
              Terkonfirmasi, Menunggu di transfer
            </p>
          </div>
          <div class="d-flex align-center mb-1">
            <div class="repair"></div>
            <p class="small_txt color_txt mb-0 ml-2">Sudah di refund</p>
          </div>
          <div class="d-flex align-center mb-1">
            <div class="nofill"></div>
            <p class="small_txt color_txt mb-0 ml-2">Rejected</p>
          </div>
        </v-card>
        <div>
          <div class="mt-3" v-if="list">
            <v-data-table
              :headers="headers"
              :items="list.data"
              hide-default-footer
              :page.sync="page"
              :items-per-page="limit"
              class="elevation-0 my-5"
            >
              <template v-slot:[`item.status`]="{ item }">
                <div
                  :class="
                    item.status_refund == 0
                      ? 'unsend'
                      : item.status_refund == 1
                      ? 'sent'
                      : item.status_refund == 2
                      ? 'repair'
                      : item.status_refund == 3
                      ? 'confirm'
                      : 'nofill'
                  "
                ></div>
              </template>
              <template v-slot:[`item.request`]="{ item }">
                <p class="ma-0 small_txt color_txt">
                  {{ item.status_refund_at }}
                </p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  depressed
                  color="red"
                  dark
                  v-if="item.status_refund == 3"
                >
                  rejected
                </v-btn>

                <div class="d-flex">
                  <v-btn
                    dark
                    depressed
                    color="cyan"
                    class="mr-2"
                    style="min-width: 10px"
                    @click.prevent="updateItem(item)"
                  >
                    <v-icon dark small> mdi-cog </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>

            <div class="text-center pa-2">
              <v-pagination
                v-model="page"
                circle
                @input="fetchItems"
                :length="list.last_page"
              ></v-pagination>
            </div>
            <v-row>
              <v-col cols="2">
                <p class="mb-0">Tampilkan</p>
                <div class="d-flex align-center">
                  <v-select
                    :items="listLimit"
                    v-model="limit"
                    dense
                    hide-details
                    outlined
                    @change="fetchItems"
                  ></v-select>
                  <p class="ml-1 mb-0">Data</p>
                </div>
              </v-col>
            </v-row>

            <div class="pa-2">
              <change-status-refund
                :dialogAlert="dialogAlert"
                @close="dialogAlert = false"
                :selected="dataUser"
              />
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import ChangeStatusRefund from "../BATCH3/Counseling/Admin/changeStatusRefund.vue";
export default {
  components: { ChangeStatusRefund },
  name: "reqRefundList",
  data() {
    return {
      list: null,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "konsultasi_transaction_id"
        },
        {
          text: "AMOUNT",
          align: "start",
          value: "status_refund_reason"
        },
        {
          text: "REQUEST DATE",
          align: "start",
          value: "request"
        },
        {
          text: "STATUS",
          align: "start",
          value: "status"
        },
        {
          text: "ACTIONS",
          align: "start",
          value: "actions"
        }
      ],
      page: 1,
      limit: 10,
      listLimit: [5, 10, 20, 50, 70, 100],
      dialogAlert: false,
      tab: "tab-1",
      dataUser: null,
      d_profilePsy: false,
      d_profileClient: false,
      d_evidence: false
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      let data = {
        page: this.page,
        limit: this.limit
      };
      this.$store.dispatch("counseling/listReqRefund", data).then(data => {
        console.log(data);
        this.list = data;
      });
    },

    updateItem(item) {
      this.dataUser = item;
      this.dialogAlert = true;
    }
  }
};
</script>

<style>
.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}
.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}
.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}
.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}
.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
</style>
