var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_bg-default _80w _100vh"},[_c('div',{staticClass:"_100top pa-3"},[_c('v-card',{staticClass:"radius-card pa-3"},[_c('h3',[_vm._v("Request Refund")]),_c('v-card',{staticClass:"pa-2 rounded-lg mt-3",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-center mb-1"},[_c('div',{staticClass:"unsend"}),_c('p',{staticClass:"small_txt color_txt mb-0 ml-2"},[_vm._v("Pending")])]),_c('div',{staticClass:"d-flex align-center mb-1"},[_c('div',{staticClass:"sent"}),_c('p',{staticClass:"small_txt color_txt mb-0 ml-2"},[_vm._v(" Terkonfirmasi, Menunggu di transfer ")])]),_c('div',{staticClass:"d-flex align-center mb-1"},[_c('div',{staticClass:"repair"}),_c('p',{staticClass:"small_txt color_txt mb-0 ml-2"},[_vm._v("Sudah di refund")])]),_c('div',{staticClass:"d-flex align-center mb-1"},[_c('div',{staticClass:"nofill"}),_c('p',{staticClass:"small_txt color_txt mb-0 ml-2"},[_vm._v("Rejected")])])]),_c('div',[(_vm.list)?_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-0 my-5",attrs:{"headers":_vm.headers,"items":_vm.list.data,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.limit},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.status_refund == 0
                    ? 'unsend'
                    : item.status_refund == 1
                    ? 'sent'
                    : item.status_refund == 2
                    ? 'repair'
                    : item.status_refund == 3
                    ? 'confirm'
                    : 'nofill'})]}},{key:"item.request",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" "+_vm._s(item.status_refund_at)+" ")])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(item.status_refund == 3)?_c('v-btn',{attrs:{"depressed":"","color":"red","dark":""}},[_vm._v(" rejected ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",staticStyle:{"min-width":"10px"},attrs:{"dark":"","depressed":"","color":"cyan"},on:{"click":function($event){$event.preventDefault();return _vm.updateItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-cog ")])],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center pa-2"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.list.last_page},on:{"input":_vm.fetchItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Tampilkan")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"items":_vm.listLimit,"dense":"","hide-details":"","outlined":""},on:{"change":_vm.fetchItems},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_c('p',{staticClass:"ml-1 mb-0"},[_vm._v("Data")])],1)])],1),_c('div',{staticClass:"pa-2"},[_c('change-status-refund',{attrs:{"dialogAlert":_vm.dialogAlert,"selected":_vm.dataUser},on:{"close":function($event){_vm.dialogAlert = false}}})],1)],1):_vm._e()])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }